import Native from './Native'
import type { InterfaceType } from './types'
import type Bridge from './Bridge'
import { ADDLISTENLIST, INTERFACE_CMDS, REMOVELISTENLIST } from './constant'

class Interface {
  // 客户端已定义的所有接口
  public static interfaces: InterfaceType[] = []

  /**
   * 初始化
   */
  public static async init() {
    // 从客户端获取所有已定义的命令动作
    this.interfaces = await Native.callNative({
      cmd: INTERFACE_CMDS.GET_COMMANDS.cmd,
      action: INTERFACE_CMDS.GET_COMMANDS.action
    })
  }

  /**
   * 把原生端已有的接口添加在Bridge中，作为Bridge的静态方法
   * 如原生端有定义接口`cmd: 'uc', action: 'login'`，web端可以使用`Bridge.uc.login`来调用该接口
   * @param BridgeObj Bridge对象
   */
  public static mixinTo(BridgeObj: Bridge & { [cmd: string]: any }) {
    for (let i = 0; i < this.interfaces.length; i++) {
      const interfaze = this.interfaces[i] as InterfaceType
      const { cmd, actions } = interfaze
      BridgeObj[cmd] = BridgeObj[cmd] || {}

      for (let k = 0; k < actions.length; k++) {
        const action = actions[k] as string
        BridgeObj[cmd][action] = this.createRunFn(cmd, action)
      }
    }
    return BridgeObj
  }

  /**
   * 创建接口实现的函数
   * 注意：原生在实现接口方法时，不能使用callback关键字
   * @param cmd 命令名称
   * @param action 执行名称
   * @returns 异步接口实现的函数
   */
  private static createRunFn(cmd: string, action: string) {
    return (params: any = {}): Promise<any> => {
      const { callBack } = params

      if (ADDLISTENLIST.indexOf(action) > -1) {
        return Native.addEventListener({
          cmd,
          action,
          params
        })
      }

      if (REMOVELISTENLIST.indexOf(action) > -1) {
        return Native.removeEventListener({
          cmd,
          action,
          params
        })
      }

      if (callBack) {
        return Native.callNativeWithCallback({
          cmd,
          action,
          params
        })
      }

      return Native.callNative({
        cmd,
        action,
        params
      })
    }
  }
}

export default Interface
