import { IS_ANDROID_WEBVIEW, IS_IOS_WEBVIEW } from '@/constant'
import type NativeInterface from './NativeInterface'
import { EventBus, randomKey } from '@kulee/helper'
import logger from '../logger'
import type { CallNativeOptions } from '../types'
import AndroidInterface from './AndroidInterface'
import IOSInterface from './IOSInterface'

class Native {
  private static nativeInterface: NativeInterface | undefined

  /**
   * 获取不同端接口的实现
   * @returns 实现实例
   */
  private static getNativeInterface(): NativeInterface {
    if (this.nativeInterface) {
      return this.nativeInterface
    }

    if (IS_ANDROID_WEBVIEW) {
      this.nativeInterface = new AndroidInterface()
    }
    if (IS_IOS_WEBVIEW) {
      this.nativeInterface = new IOSInterface()
    }

    if (!this.nativeInterface) {
      throw new Error('Native interface not found!')
    }

    return this.nativeInterface
  }

  /**
   * 调用原生方法
   * @param options 调用原生方法所需参数
   */
  private static exec(options: ExecOptions): void {
    this.getNativeInterface()
    this.nativeInterface?.exec(options)
  }

  /**
   * 创建回调，调用原生接口，在只回调一次时使用
   * @param options 调用接口需要的参数
   * @returns 异步返回执行后的数据
   */
  public static async callNative(options: CallNativeOptions): Promise<any> {
    logger.info('callNative', options)

    return new Promise((resolve, reject) => {
      const callbackId = randomKey()

      const callback = (error: Error | null, payload: any) => {
        if (error) {
          reject(error)
        } else {
          resolve(payload)
        }
      }

      // 把回调加入事件监听器
      EventBus.addEventListener(callbackId, callback)

      const nativeExecOptions: ExecOptions = {
        callbackId,
        ...options
      }

      // 开始调用原生方法
      this.exec(nativeExecOptions)
    })
  }

  /**
   * 调用原生异步接口 带callBack
   * @param options 调用接口需要的参数
   */
  public static async callNativeWithCallback(options: CallNativeOptions): Promise<any> {
    logger.info('callNativeWithCallback', options)

    const { cmd, action, params } = options
    const { callBack, ...restParams } = params
    if (!callBack) {
      logger.error(`none callBack in the params, please use 'callNative' instead`)
      return
    }

    const callbackId = randomKey()
    // 把回调加入事件监听器
    EventBus.addEventListener(callbackId, callBack)
    const nativeExecOptions: ExecOptions = {
      cmd,
      action,
      callbackId,
      params: restParams
    }

    // 开始调用原生方法
    this.exec(nativeExecOptions)
  }

  /**
   * 注册监听事件
   * @param eventName 事件名称
   * @param callback 回调方法
   */
  // public static addEventListener(eventName: string, callback: (...args: any[]) => any) {
  public static async addEventListener(options: CallNativeOptions): Promise<any> {
    const { cmd, action, params } = options
    const { callBack, ...restParams } = params
    const eventName = restParams.eventName

    logger.info('addEventListener', eventName)

    // 把回调加入事件监听器
    EventBus.addEventListener(eventName, callBack)

    const nativeExecOptions: ExecOptions = {
      cmd,
      action,
      params: restParams
    }

    this.exec(nativeExecOptions)
  }

  /**
   * 移除监听事件
   * 暂时只实现移除事件下所有的回调
   * @param eventName 事件名称
   */
  // public static removeEventListener(eventName: string) {
  public static async removeEventListener(options: CallNativeOptions): Promise<any> {
    const { ...restParams } = options.params
    const eventName = restParams.eventName

    logger.info('removeEventListener', eventName)

    // 移除回调监听
    EventBus.removeEventListener(eventName)

    const nativeExecOptions: ExecOptions = {
      ...options
    }

    this.exec(nativeExecOptions)
  }
}

export default Native
