import logger from './logger'
import Bridge from './Bridge'
import Interface from './Interface'

const userAgent = navigator.userAgent

if (userAgent.indexOf('KuleWebview') < 0) {
  // 当运行环境不是原生APP时，Bridge不执行，window下不挂载Bridge
  logger.warn('Not running on KuleWebview, jsbridge is invalid.')

  Bridge.onReadyProxy(new Error('Not running on KuleWebview, jsbridge is invalid.'))
} else {
  Interface.init()
    .then(() => {
      Interface.mixinTo(Bridge)

      Bridge.onReadyProxy()
    })
    .catch(error => {
      Bridge.onReadyProxy(error)
    })
}
// 把Bridge挂载到window
window.Bridge = Bridge
