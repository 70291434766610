import { EventBus } from '@kulee/helper'
import logger from './logger'

class Callback {
  /**
   * 异步接口回调执行入口
   * @param callbackId 异步接口回调方法ID
   * @param error 错误信息
   * @param payload 回调带的数据
   */
  public static exec(callbackId: string, error: Error, payload?: string) {
    logger.log('exec native callback:', callbackId, error, payload)

    EventBus.dispatch(callbackId, error, this.parseCallbackPayload(payload))
  }

  /**
   * 事件监听回调执行入口
   * @param eventName 事件名称
   * @param payload 回调带的数据
   */
  public static execListen(eventName: string, payload?: string) {
    logger.log('execListen native callback:', eventName, payload)

    EventBus.dispatch(eventName, this.parseCallbackPayload(payload))
  }

  private static parseCallbackPayload(payload?: string): any {
    if (typeof payload === 'undefined') {
      return undefined
    }

    let payloadAfterParse = payload
    try {
      payloadAfterParse = JSON.parse(payload)
    } catch (error) {}

    return payloadAfterParse
  }
}

export default Callback
