import { Logger } from '@kulee/helper'

// 日志前缀，`__CLAZZ__`在JSSDK编译时，会自动以JSSDK的名称来替换
export const LOGGER_PREFIX = '__CLAZZ__'
// 日志输出等级
export const LOGGER_LEVEL = Logger.LEVEL.INFO
// 原生端默认必须定义的命令
export const INTERFACE_CMDS = {
  // 获取已定义的所有命令
  GET_COMMANDS: {
    cmd: 'system',
    action: 'getCommands'
  },
  // 添加监听事件
  ADD_EVENT_LISTENER: {
    cmd: 'system',
    action: 'addEventListener'
  },
  // 移除监听事件
  REMOVE_EVENT_LISTENER: {
    cmd: 'system',
    action: 'removeEventListener'
  }
}
// 是否在android webview中
export const IS_ANDROID_WEBVIEW = typeof window.AndroidInterface !== 'undefined'
// 是否在iOS webview中
export const IS_IOS_WEBVIEW = typeof window.webkit?.messageHandlers.iOSInterface !== 'undefined'

export const ADDLISTENLIST = ['addEventListener']
export const REMOVELISTENLIST = ['removeEventListener']
