import Callback from './Callback'

class Bridge {
  // 给原生端用的回调
  public static Callback = Callback

  private static onReadyCache: null | ((error?: Error) => void) = null
  private static onReadyData: OnReadyData = {
    isReady: false
  }

  // Bridge准备就绪回调
  public static onReadyProxy = (error?: Error) => {
    if (this.onReadyCache) {
      this.onReadyCache(error)
    } else {
      this.onReadyData.isReady = true
      error && (this.onReadyData.param = error)
    }
  }

  static set onReady(v: (error?: Error) => void) {
    this.onReadyCache = v

    if (this.onReadyData.isReady) {
      this.onReadyCache(this.onReadyData.param)
    }
  }

  static get onReady(): (error?: Error) => void {
    return this.onReadyCache as (error?: Error) => void
  }

  // 其他静态方法会从原生中获取，并以静态方式的形式添加到该类中
}

interface OnReadyData {
  isReady: boolean
  param?: Error
}

export default Bridge
